import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = React.createContext({
    isLoggedIn: false,
    user: null,
    isLoading: false,
    getUser: () => {},
    logout: () => {},
    error: null,
});

export const AuthProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;

    const getUser = async () => {
        // console.log('INSIDE GET USER');
        // if provider === google call google api
        // if provider === siberian call siberian api

        // console.log('INSIDE GET USER');
        // console.log('TOPBAR');
        const st = localStorage.getItem('st');
        if (st) {
            const provider = localStorage.getItem('provider');
            // console.log('in st');
            // if (provider && provider.toLowerCase() === 'siberian') {
            try {
                // const response = await fetch(`${API_URL}/auth/login/success?st=${st}`, {
                // /auth/validate/session?st
                const response = await fetch(`${API_URL}/auth/validate/session?st=${st}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                    },
                });
                if (response.status !== 200) throw new Error('authentication has been failed.');
                const data = await response.json();
                // console.log('data from validate: ', data);
                if (data.success) {
                    // console.log(data);
                    setIsLoggedIn(true);
                    // setUser({
                    //     ...data.user,
                    //     displayName: data.user.name,
                    //     photos: [{ value: user.providerData.picture }],
                    // });
                    setUser({
                        email: data.user.email,
                        displayName: data.user.name,
                        photos: [{ value: data.user.providerData?.picture ? data.user.providerData?.picture : ''  }],
                    });
                    setLoading(false);
                } else {
                    throw new Error('Login Error');
                }
            } catch (err) {
                // console.log(err.message);
                setError(err.message);
                setLoading(false);
            }

            // }
        } else {
            // if (provider.toLowerCase() === 'google') {
            // google
            fetch(`${API_URL}/auth/login/success`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                },
            })
                .then((response) => {
                    // console.log('FAILED TO GET USER');
                    if (response.status === 200) return response.json();
                    throw new Error('authentication has been failed!');
                    // check google signin error here and setError(res.error)
                })
                .then((resObject) => {
                    if (resObject.success) {
                        // console.log('resObject: ', resObject);

                        setIsLoggedIn(true);
                        setUser(resObject.user);
                        setLoading(false);
                        localStorage.setItem('st', resObject.st);
                    } else {
                        // console.log('catched error here: ', resObject.error);
                        // toast.error(resObject.error, {
                        //     autoClose: 2000,
                        //     theme: 'colored',
                        //     hideProgressBar: true,
                        // });
                        setLoading(false);
                        setError(resObject.error);
                    }
                    // setLoading(false);
                })
                .catch((err) => {
                    // console.log(err);
                    setLoading(false);
                    setError(err.message);
                });

            // }
        }
    };

    const logoutSiberianUser = () => {
        return new Promise(async (resolve, reject) => {
            const accessToken = localStorage.getItem('siberian_access_token');
            const st = localStorage.getItem('st');
            if (st) {
                localStorage.removeItem('st');
            }
            if (accessToken) {
                const response = await axios.delete(`${API_URL}/auth/siberian/logout`);
                // console.log('siberianLogout response: ', response);
            }
            localStorage.removeItem('siberian_access_token');
            localStorage.removeItem('siberian_refresh_token');
            localStorage.removeItem('redirectInfo');
            setIsLoggedIn(false);
            resolve();
        });
    };

    const authValue = {
        isLoggedIn: isLoggedIn,
        user: user,
        getUser: getUser,
        isLoading: loading,
        logout: logoutSiberianUser,
    };

    // console.log('Auth Context -> ', authValue);
    return <AuthContext.Provider value={authValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
