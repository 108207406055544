import { useEffect, useState, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { Alert } from 'react-bootstrap';
import AuthContext from '../context/auth-context';

const ProtectedRoute = (props) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const authCtx = useContext(AuthContext);

    const checkIfUserAlreadyLoggedIn = () => {
        const accessToken = localStorage.getItem('siberian_access_token');
        const refreshToken = localStorage.getItem('siberian_refresh_token');
        // console.log('tokens from locl storage: ', accessToken, refreshToken);
        // const accessToken = localStorage.getItem('st');
        if (accessToken) {
            const decodedAccessToken = jwt_decode(accessToken);
            const user = {
                displayName: decodedAccessToken.name,
                photos: [{ value: '' }],
                usernaame: decodedAccessToken.name,
                email: decodedAccessToken.email,
            };
            // console.log('decoded: ', user);
            setIsLoggedIn(user);
        }
        return;
    };

    useEffect(() => {
        // console.log('API_URL: ', API_URL);

        const getUser = async () => {
            // console.log('INSIDE GET USER');
            const st = localStorage.getItem('st');
            // let url = `${API_URL}/auth/login/success`;
            console.log('Protectedst');

            if (st) {
                // url = `${API_URL}/auth/login/success?st=${st}`;
                const provider = localStorage.getItem('provider');
                // console.log('in st');
                if (provider && provider.toLowerCase() === 'siberian') {
                    try {
                        const response = await fetch(`${API_URL}/auth/validate/session?st=${st}`, {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Credentials': true,
                            },
                        });
                        if (response.status !== 200) throw new Error('authentication has been failed!');
                        const data = await response.json();
                        // setIsLoggedIn(true);

                        if (data.success) {
                            setIsLoggedIn(true);
                        } else {
                            console.log(data);
                            throw new Error('Login Error');
                        }
                    } catch (err) {
                        // setIsLoggedIn(true);
                        console.log(err.message);
                        setIsLoggedIn(false);
                        setErrorMessage(err.message);
                        // setError(err.message);
                    }
                }
            } else {
                fetch(`${API_URL}/auth/login/success`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true,
                    },
                })
                    .then((response) => {
                        // console.log('FAILED TO GET USER');
                        if (response.status === 200) return response.json();
                        throw new Error('authentication has been failed!');
                    })
                    .then((resObject) => {
                        // console.log('RESULT OBJECT');
                        // dispatch(oAuthLoginUser(resObject));
                        // console.log('resObject-> ', resObject);
                        // setIsLoggedIn(true);
                        if (resObject.success) setIsLoggedIn(true);
                        if (!resObject.success) {
                            throw new Error('Collections can be viewed only by logged-in users.');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsLoggedIn(false);
                        setErrorMessage(err.message);
                    });
            }
        };
        // getUser();

        // authCtx.getUser();

        // checkIfUserAlreadyLoggedIn();
    }, []);

    return (
        <>
            {/* {authCtx.isLoggedIn
                ? props.children
                : errorMessage && (
                      <Alert variant="danger" className="my-2 text-center">
                          {'Collections can be viewed only by logged-in users.'}
                      </Alert>
                  )} */}
            {!authCtx.isLoading && authCtx.isLoggedIn && props.children}
            {!authCtx.isLoading && !authCtx.isLoggedIn && (
                <Alert variant="danger" className="my-2 text-center">
                    {'Collections can be viewed only by logged-in users.'}
                </Alert>
            )}
        </>
    );
};

export default ProtectedRoute;
