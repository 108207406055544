// @flow
import { CdnDataActionTypes } from './constants';
// import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const cdnUrl = `https://cdn.siberian.dev/code/mapping.json`;

const fetch = async () => {
    const response = await axios.get(cdnUrl);
    console.log('fetch CDN thunk response: ', response);
    return response.data;
};
// const dispatch = useDispatch();
const INIT_STATE = {
    data: fetch, //function call changed to function reference for page performance
    loading: false,
};

type CdnDataAction = { type: string, payload?: string | null };

type State = {
    data: null,
    loading?: boolean,
};

const CdnData = (state: State = INIT_STATE, action: CdnDataAction): any => {
    console.log('FROM CDN REDUCER: ', action.type);
    switch (action.type) {
        case CdnDataActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CdnDataActionTypes.FETCH_CDN_DATA:
                    console.log('DATA FROM CDN REDUCER:--> ', action);
                    return {
                        ...state,
                        cdnData: action.payload.data,
                        loading: false,
                    };
                default:
                    return state;
            }
        case CdnDataActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CdnDataActionTypes.FETCH_CDN_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        case CdnDataActionTypes.FETCH_CDN_DATA:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default CdnData;
