// @flow
import { APICore } from './apiCore';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const api = new APICore();

// OAUTH Actions
// TODO change fetchtodos
const oAuthLogin = createAsyncThunk('fetchTodos', async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.get(`${API_URL}/auth/google`);
    console.log('create thunk response: ', response);
    return response.data;
});

const fetchCdnData123 = createAsyncThunk('fetchCdnData123', async () => {
    // base CDN url
    const cdnUrl = `https://cdn.siberian.dev/code/mapping.json`;
    // const response = await axios.get(cdnUrl);
    console.log('++++++++ FETCH CDN 123  THUNK +++++++++ ');
    return api.get(cdnUrl);
});

// account
function login(params: any): any {
    console.log('params: ', params);
    const baseUrl = '/login/';
    return api.create(`${baseUrl}`, params);
}

function logout(): any {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, fetchCdnData123, oAuthLogin, logout, signup, forgotPassword, forgotPasswordConfirm };
