// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import OAuth from './oAuth/reducers';
import CdnData from './cdnData/reducers';

export default (combineReducers({
    Auth,
    Layout,
    OAuth,
    CdnData,
}): any);
