// import { call, put, takeLatest, all, fork, takeEvery } from 'redux-saga/effects';
// import axios from 'axios';
// import { APICore } from '../../helpers/api/apiCore';
// // import { oAuthLogin as oAuthLoginApi } from '../../helpers/';
// import { oAuthLogin as oAuthLoginApi } from '../../helpers/api/auth';

// // import { FETCH_GOOGLE_AUTH_DATA_REQUEST, fetchGoogleAuthDataSuccess, fetchGoogleAuthDataFailure } from './actions';
// import { oAuthApiResponseSuccess, oAuthApiResponseError } from './actions';
// import { OAuthActionTypes } from './constants';

// const api = new APICore();

// function* oAuthLogin() {
//     try {
//         const response = yield call(oAuthLoginApi, {});
//         console.log('response from oAuthSaga', response);
//         const user = response.data;

//         api.setLoaggedInUser(user);
//         yield put(oAuthApiResponseSuccess(OAuthActionTypes.LOGIN_USER, user));
//     } catch (error) {
//         yield put(oAuthApiResponseError(OAuthActionTypes.LOGIN_USER, error));
//         api.setLoggedInUser(null);
//     }
// }

// export function* watchOAuthLoginUser(): any {
//     yield takeEvery(OAuthActionTypes.LOGIN_USER, oAuthLogin);
// }

// function* oAuthSaga(): any {
//     yield all([fork(watchOAuthLoginUser)]);
// }

// export default oAuthSaga;

// 2nd approach
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { FETCH_GOOGLE_AUTH_DATA_REQUEST, fetchGoogleAuthDataSuccess, fetchGoogleAuthDataFailure } from './actions';

function* fetchGoogleAuthDataSaga() {
    const API_URL = process.env.REACT_APP_API_URL;
    console.log('inside fetch googleAuth...');
    try {
        const response = yield call(axios.get, `${API_URL}/auth/google`);
        yield put(fetchGoogleAuthDataSuccess(response.data));
    } catch (error) {
        yield put(fetchGoogleAuthDataFailure(error));
    }
}

export function* watchFetchGoogleAuthData() {
    yield takeLatest(FETCH_GOOGLE_AUTH_DATA_REQUEST, fetchGoogleAuthDataSaga);
}
