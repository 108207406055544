// @flow
import { CdnDataActionTypes } from './constants';

type CdnDataAction = { type: string, payload?: {} | null };

// common success
export const cdnDataApiResponseSuccess = (actionType: string, data: any): CdnDataAction => ({
    type: CdnDataActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const cdnDataApiResponseError = (actionType: string, error: string): CdnDataAction => ({
    type: CdnDataActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCdnData = (actionType: string, data: any): CdnDataAction => {
    console.log('ACTYPE: ', actionType, '\n DATA:', data);
    return {
        type: CdnDataActionTypes.FETCH_CDN_DATA,
        payload: { actionType, data },
    };
};
