// // @flow
// import { OAuthActionTypes } from './constants';

// type OAuthAction = { type: string, payload: {} | string };
// // common success
// export const oAuthApiResponseSuccess = (actionType: string, data: any): OAuthAction => ({
//     type: OAuthActionTypes.API_RESPONSE_SUCCESS,
//     payload: { actionType, data },
// });

// // common error
// export const oAuthApiResponseError = (actionType: string, error: string): OAuthAction => ({
//     type: OAuthActionTypes.API_RESPONSE_ERROR,
//     payload: { actionType, error },
// });

// export const oAuthLoginUser = (actionType: string = '@@oAuth/LOGIN_USER'): OAuthAction => ({
//     type: OAuthActionTypes.LOGIN_USER,
//     payload: { actionType },
// });

// 2nd approach
export const FETCH_GOOGLE_AUTH_DATA_REQUEST = 'FETCH_GOOGLE_AUTH_DATA_REQUEST';
export const FETCH_GOOGLE_AUTH_DATA_SUCCESS = 'FETCH_GOOGLE_AUTH_DATA_SUCCESS';
export const FETCH_GOOGLE_AUTH_DATA_FAILURE = 'FETCH_GOOGLE_AUTH_DATA_FAILURE';

export const fetchGoogleAuthDataRequest = () => ({
    type: FETCH_GOOGLE_AUTH_DATA_REQUEST,
});

export const fetchGoogleAuthDataSuccess = (data) => ({
    type: FETCH_GOOGLE_AUTH_DATA_SUCCESS,
    payload: data,
});

export const fetchGoogleAuthDataFailure = (error) => ({
    type: FETCH_GOOGLE_AUTH_DATA_FAILURE,
    error,
});
