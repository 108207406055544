import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './context/auth-context';

import FacebookPixel from 'react-facebook-pixel';

import './i18n';

import App from './App';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
const store = configureStore({});

// FacebookPixel.init('179203363578105'); // Replace 'YOUR_PIXEL_ID' with your actual Facebook Pixel ID
FacebookPixel.init('767633693935226');


console.log('STORE: ', store);
ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Provider>,
    document.getElementById('root')
);
