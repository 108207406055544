// // @flow
// import { OAuthActionTypes } from './constants';

// import { APICore } from '../../helpers/api/apiCore';

// // import { FETCH_GOOGLE_AUTH_DATA_SUCCESS, FETCH_GOOGLE_AUTH_DATA_FAILURE } from './actions';

// const api = new APICore();

// const INIT_STATE = {
//     googleAuthData: null,
//     error: null,
// };

// type OAuthAction = {
//     type: string,
//     payload: { actionType?: string, data?: any, error?: string },
// };

// type State = { user?: {} | null, loading?: boolean, +value?: boolean };

// const OAuth = (state: State = INIT_STATE, action: OAuthAction): any => {
//     console.log('OAUTH ACTION--: ', action.type);
//     console.log('action.type from oAuth reducer: ', action.type);

//     switch (action.type) {
//         case OAuthActionTypes.API_RESPONSE_SUCCESS:
//             switch (action.payload.actionType) {
//                 case OAuthActionTypes.LOGIN_USER: {
//                     console.log('_____from oauth reducer_____');
//                     console.log('state from oAuth reducer: ', state);
//                     console.log('googleAuthData from oAuth reducer: ', action.payload);
//                     console.log('userLoggedIn from oAuth reducer: ', true);
//                     console.log('loading from oAuth reducer: ', false);
//                     return {
//                         ...state,
//                         googleAuthData: action.payload,
//                         // error: null,
//                         userLoggedIn: true,
//                         loading: false,
//                     };
//                 }
//                 default:
//                     return { ...state };
//             }
//         case OAuthActionTypes.API_RESPONSE_ERROR:
//             switch (action.payload.actionType) {
//                 case OAuthActionTypes.LOGIN_USER: {
//                     return {
//                         ...state,
//                         googleAuthData: null,
//                         error: action.error,
//                         userLoggedIn: false,
//                         loading: false,
//                     };
//                 }
//                 default:
//                     return { ...state };
//             }
//         case OAuthActionTypes.LOGIN_USER:
//             console.log('OAUTH- payload: ', action.payload);
//             console.log('oAUTH- state: ', state);

//             // return { ...state, loading: true, userLoggedIn: false };
//             return {
//                 ...state,
//                 googleAuthData: action.payload,
//                 // error: null,
//                 userLoggedIn: true,
//                 loading: false,
//             };

//         default:
//             return { ...state };
//     }
// };

// export default OAuth;

// 2nd approach
import { FETCH_GOOGLE_AUTH_DATA_SUCCESS, FETCH_GOOGLE_AUTH_DATA_FAILURE } from './actions';

const initialState = {
    googleAuthData: null,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GOOGLE_AUTH_DATA_SUCCESS:
            return {
                ...state,
                googleAuthData: action.payload,
                error: null,
            };
        case FETCH_GOOGLE_AUTH_DATA_FAILURE:
            return {
                ...state,
                googleAuthData: null,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;
