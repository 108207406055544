// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import { watchFetchGoogleAuthData } from './oAuth/saga';
import cdnDataSaga from './cdnData/saga';

export default function* rootSaga(): any {
    yield all([authSaga(), layoutSaga(), watchFetchGoogleAuthData(), cdnDataSaga()]);
}
