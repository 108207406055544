// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchCdnData123 as fetchCdnDataApi } from '../../helpers';
import { CdnDataActionTypes } from './constants';
import { cdnDataApiResponseSuccess, cdnDataApiResponseError } from './actions';
import axios from 'axios';
// import { fetchCdnData as fetchCdnDataApi } from '../../helpers';

const cdnUrl = `https://cdn.siberian.dev/code/mapping.json`;

// const fetchCdnDataApi = async () => {
//     const response = await axios.get(cdnUrl);
//     console.log('fetch CDN thunk response2: ', response);
//     return response.data;
// };

function* fetchCdnData(): any {
    try {
        const response = yield call(fetchCdnDataApi); // TODO import this part
        const data = response.data;
        console.log('-------------- data from cdn saga ------------', response);
        // set data response
        yield put(cdnDataApiResponseSuccess(CdnDataActionTypes.FETCH_CDN_DATA, data));
    } catch (error) {
        yield put(cdnDataApiResponseError(CdnDataActionTypes.FETCH_CDN_DATA, error));
        //set data here
    }
}

export function* watchFetchCdnData(): any {
    yield takeEvery(CdnDataActionTypes.FETCH_CDN_DATA, fetchCdnData);
}

function* CdnDataSaga(): any {
    yield all([fork(watchFetchCdnData)]);
}

export default CdnDataSaga;
